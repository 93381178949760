// dr = Draft

.dr-DraftList_Headline {
  font-size: $font-size-xs;
  color: $gray;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: $padding-medium-vertical;
}

.dr-DraftList_Wrapper {
  display: flex;
  flex-direction: row;
  padding-bottom: $padding-large-vertical;
  margin-bottom: $padding-large-vertical;
  overflow: hidden;
  overflow-x: auto;
}

.dr-DraftList_Item {
  display: flex;
  flex-direction: column;
  background: $white;
  margin-right: $padding-large-vertical;
  border-radius: $border-radius-base;
  box-shadow: $box-shadow-base;
  padding: $padding-medium-vertical * 3;
  color: $black;
  font-size: $font-size-xs;
  flex-shrink: 0;
  border: $width-border-thick solid $white;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    border: $width-border-thick solid $gray-light;
    background: $white;
  }
}

.dr-DraftList_Name {
  font-weight: bold;
  font-size: $font-size-h6;
  max-width: 20ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dr-DraftList_Place {
  color: $gray;
  max-width: 20ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
