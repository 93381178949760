.mo-Options_Wrapper {
  background: white;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  padding: $padding-large-vertical * 3;
  max-width: $max-width-modal;
  position: relative;

  &:focus {
    outline: 0;
  }
}

.mo-Options_InputField {
  border: $width-border-thin solid $gray-light !important;
  width: 100%;

  &:focus {
    border-bottom: $width-border-thin solid $gray-light !important;
  }
}

.mo-Options_ItemWrapper {
  margin-bottom: $padding-large-vertical;
}

.mo-Options_Range {
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
}

.mo-Options_Overlay {
  background: rgba($white, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
}

.mo-Options_Headline {
  font-weight: bold;
  font-size: $font-size-h3;
  line-height: 1;
}

.mo-Options_CloseButton {
  font-size: $font-size-h2;
  background: none;
  border: 0;
  position: absolute;
  top: $padding-large-vertical;
  right: $padding-large-vertical;

  &:active,
  &:focus,
  &:hover {
    background: none;
    border: 0;
  }
}

.mo-Options_Button {
  background: none;
  border: 0;

  &:active,
  &:focus,
  &:hover {
    background: none;
    border: 0;
  }
}

.mo-Options_HeadlineWrapper {
  margin-top: $padding-large-vertical * 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: $padding-large-vertical;
}

.mo-Options_HeadlineIcon {
  font-size: $font-size-h2;
  margin: 0;
  margin-right: $padding-large-vertical;
}

.mo-Options_Subheadline {
  font-size: $font-size-h6;
  color: $gray;
  font-weight: bold;
  margin: 0 0 $padding-large-vertical / 2;
}

.mo-Options_ButtonWrapper {
  margin-top: $padding-large-vertical * 2;
}
