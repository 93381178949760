.hd-TitleHeader_Back {
  font-size: $font-size-h2;
  background: none;
  border: 0;

  &:hover, &:active, &:focus {
    background: none;
    border: 0;
  }
}

.hd-TitleHeader_TitleWrapper {
  display: flex;
  flex-direction: column;
  text-align: right;
  flex-grow: 1;
}

.hd-TitleHeader_Title {
  font-size: $font-size-h3;
  font-weight: bold;
  margin: 0;
  margin-bottom: $padding-large-vertical / 2;
}

.hd-TitleHeader_Subtitle {
  font-size: $font-size-h6;
  color: $gray;
  margin: 0;
}

.hd-TitleHeader_Link {
  text-decoration: underline;
  cursor: pointer;
}
