.dn-Input_Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: $padding-large-vertical;
}

.dn-Input_Autocomplete {
  border-radius: 0 0 $border-radius-base;
  border-top: 0;
  color: $gray;
  font-size: $font-size-h6;
  overflow: hidden;
  width: 100%;
  padding: $padding-large-vertical 0;
  max-height: $max-height-autocomplete;
  overflow-y: auto;
  z-index: $zi-highest;
  position: absolute;
  top: 100%;
  background: $white;
  box-shadow: $box-shadow-base;
  border-top: $white;
} 

.dn-Input_Item {
  display: flex;
  justify-content: space-between;
  padding: $padding-medium-vertical $padding-large-vertical;
  cursor: pointer;
}

.dn-Input_Item-primary {
  color: $gray;
}

.dn-Input_Item-secondary {
  color: $gray-medium;
  text-align: right;
}

.dn-Input_Item-selected {
  background-color: $gray-lightest;
  color: $black;
}

.dn-Input_Textfield {
  margin-bottom: 0 !important;
  box-shadow: none !important;
}

.dn-Input_ValueWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 100%;
}

.dn-Input_DishInput {
  margin-bottom: 0 !important;
}

.dn-Input_AutocompleteGroup {
  margin: $padding-large-vertical 0;
}

.dn-Input_AutoGroupHeader {
  color: $green;
  font-weight: bold;
  font-size: $font-size-h6;
  padding: $padding-medium-vertical $padding-large-vertical;
}