.dn-Form_Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.dn-Form_Container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.dn-Form_InnerForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.dn-Form_InputText {
  font-weight: normal;
}

.dn-Form_InnerContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.dn-Form_FormArea {
  flex-grow: 1;
}

.dn-Form_ButtonArea {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  margin-top: $padding-large-vertical * 2;
}

.dn-Form_HeroIcon {
  font-size: $hero-icon-font-size;
  width: 100%;
  text-align: center;
}

.dn-Form_InputLabel {
  display: block;
  color: $black;
  font-size: $font-size-h5;
  font-weight: bold;
  margin-bottom: $padding-small-vertical;
}

.dn-Form_InputWrapper {
  display: flex;
  align-items: center;
  background: $white;
  border-radius: $border-radius-base;
  box-shadow: $box-shadow-base;
  margin-bottom: $padding-large-vertical;
}

.dn-Form_InputSearchIcon {
  padding-right: $padding-large-vertical;
}

.dn-Form_InputLocationIcon {
  font-size: $font-size-h4;
  margin-right: $padding-large-vertical;
  color: $gray;
}

.dn-Form_InputLocationIcon-active {
  color: $blue;
}

.dn-Form_InputRed {
  font-size: $font-size-sm;
  color: $red;
  margin-bottom: $padding-large-vertical;
}

.dn-Form_PlaceSelect {
  width: 100% !important;
  margin-bottom: 0 !important;
  border-bottom: 0 !important;
  padding-left: $padding-large-vertical !important;
  box-shadow: none !important;
}

.dn-Form_Textarea {
  background: $white;
  border-radius: $border-radius-base;
  box-shadow: $box-shadow-base;
  margin-bottom: $padding-large-vertical;
  padding: $padding-large-vertical;
  height: auto;
}