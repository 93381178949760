.hd-Header_NavBarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: $padding-large-vertical;
  margin-bottom: $padding-large-vertical;
}

.hd-Header_LogoContainer {
  flex-grow: 1;
  line-height: 1;
}

.hd-Header_MenuContainer {
  order: 2;
}

.hd-Header_Navbar {
  color: $black;
  box-shadow: none;

  a {
    color: $black;
  }
}

.hd-Header_Logo {
  font-size: $font-size-h4;
}

.hd-Header_Profile {
  display: flex;
  align-items: center;
}

.hd-Header_ProfilePicture {
  border-radius: 50%;
  width: $header-profile-pic-size;
  height: $header-profile-pic-size;
  margin-right: $padding-large-vertical;
}

.hd-Header_Link {
  margin-right: -15px;
}

nav ul li {
  &:not(:last-child) {
    padding: 0 15px;
  }
}

.hd-Header_Back {
  font-size: $font-size-sm;
  font-weight: bold;
  padding-left: 0;
}
