.to-Dropdown_OptionIcon {
  border: 0;
  background: none;

  &:hover,&:active,&:focus {
    background: none;
  }
}

.to-Dropdown_OptionItem {
  display: block;
  padding: $padding-large-vertical;
  color: $gray-medium;
  font-size: $font-size-h6;
  background: none;
  border: 0;

  &:not(:last-child) {
    border-bottom: $width-border-thin solid $gray-light;
  }

  &:hover,&:active,&:focus {
    color: $black;
    background: $gray-lightest;
    cursor: pointer;
  }
}

.to-Dropdown_OptionsWrapper {
  position: absolute;
  top: $padding-large-vertical * 3;
  z-index: $zi-max;
  margin-bottom: $padding-large-vertical * 5;
  right: 0;
  display: none;
  background: $white;
  padding: $padding-medium-vertical 0;
  opacity: 0;
  transition: opacity $transition-delay-short;
  border-radius: $border-radius-base;
  border: $width-border-thin solid $gray-light;
}

.to-Dropdown_OptionsWrapper-visible {
  display: block;
  opacity: 1;
}
