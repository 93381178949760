.dr-TagList_Wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dr-TagList_Item {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $white;
  margin-bottom: $padding-large-vertical;
  border-radius: $border-radius-base;
  box-shadow: $box-shadow-base;
  padding: $padding-medium-vertical * 3;
  color: $black;
}

.dr-TagList_Title {
  font-size: $font-size-h3;
  line-height: 1;
}

.dr-TagList_Count {
  color: $gray-medium;
  font-size: $font-size-h6;
}
