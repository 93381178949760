.pr-Edit_AvatarWrapper {
  display: flex;
  align-items: center;
  margin-bottom: $padding-large-vertical * 2;
}

.pr-Edit_AvatarImage {
  margin-right: $padding-large-vertical;
}

.pr-Edit_AvatarImageEdit {
  text-decoration: underline;
  color: $gray;
}

.pr-Edit_AvatarImageDelete {
  text-decoration: underline;
  color: $gray;
  padding-left: $padding-large-vertical;
}

.pr-Edit_Input {
  border: $width-border-thin solid $gray-light !important;
  width: 100%;

  &:focus {
    border-bottom: $width-border-thin solid $gray-light !important;
  }
}

.pr-Edit_InputWrapper {
  margin-bottom: $padding-large-vertical;
  width: 100%;
}

.pr-Edit_Form {
  max-width: $max-width-form;
  margin: auto;
}