.mo-Toggle_Button {
  background: none;
  padding: $padding-large-vertical $padding-medium-vertical;
  color: $gray-light;
  flex-grow: 1;
  border: none;
  font-size: $font-size-h6;
  line-height: 1;

  &:focus {
    background: none;
    color: $green;
  }
}

.mo-Toggle_Button-active {
  color: $green;
}

.mo-Toggle_Wrapper {
  flex-shrink: 0;
}
