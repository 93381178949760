.lo-Wrapper {
  position: absolute;
  background: $white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0;
  animation: fadeInOut 3s infinite;
}

.lo-Wrapper_Icon {
  width: 25%;
  height: auto;
  margin-bottom: $padding-large-vertical;
  max-width: 200px;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
