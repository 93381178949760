$header-font: "sofia-pro", -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
$body-font: "sofia-pro", -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
$padding-small-vertical: 2px;
$padding-medium-vertical: 5px;
$padding-large-vertical: 10px;
$padding-xlarge-vertical: $padding-large-vertical * 5;
$padding-huge-vertical: $padding-large-vertical * 10;

$font-size-xl: 4rem;
$font-size-h1: 2rem;
$font-size-h2: 1.85rem;
$font-size-h3: 1.5rem;
$font-size-h4: 1.25rem;
$font-size-h5: 1rem;
$font-size-h6: .85rem;
$font-size-sm: .7rem;
$font-size-xs: .6rem;
$hero-icon-font-size: 12rem;

$line-height-base: 1.2;

$font-weight-bold: 500;

$white: #fff;
$black: #000;
$blackblue: #004577;
$shadowcolor: #ccc;
$gray: #777;
$gray-medium: #7A7A7A;
$gray-light: #ccc;
$gray-lighter: #e4e4e4;
$gray-lightest: #FAFAFC;
$red: #ff0000;
$blue: #2398ff;
$blue-lighter: #43a7ff;
$green: #4BD292;

$rating4: #a2e732;
$rating3: #f7d828;
$rating2: #ff9900;
$rating1: $red;

$width-border-thin: 1px;
$width-border-thick: 2px;
$width-border-fat: 3px;

$height-tag-chip: 24px;

$primary-color: $black;
$brand-color: $green;

$header-profile-pic-size: 30px;
$profile-page-pic-size: 50px;
$max-height-form-image: 500px;
$max-width-modal: 300px;
$max-width-form: 300px;

$max-height-autocomplete: 300px;

$max-length-text: 30ch;

$screen-sm-min: 540px;
$screen-md-min: 768px;
$screen-lg-min: 1024px;
$screen-xl-min: 1280px;

$transition-delay-short: 0.25s;

$box-shadow-base: 1px 1px 15px rgba($blackblue, 0.1);
$drop-shadow-large: 0 0 25px rgba($black, 0.05);

$zi-high: 100;
$zi-highest: 1000;
$zi-max: 1001;

$border-radius-base: 5px;
$border-radius-big: 10px;
$border-radius-medium: 20px;
$border-radius-large: 25px;

$height-footer: 75px;
