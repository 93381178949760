.do-Toggle_Wrapper {
  display: flex;
  flex-shrink: 0;
}

.do-Toggle_Button {
  background: $white;
  padding: $padding-large-vertical $padding-large-vertical + 2px;
  color: $green;
  border: none;
  font-size: $font-size-h6;
  margin-right: $padding-medium-vertical;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-base;
}

.do-Toggle_Button-active {
  background: $green;
  color: $white;
}
