.sw-Footer_Spacer {
  height: $height-footer;
}

.sw-Footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $gray-lightest;
  z-index: $zi-highest;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 70px;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba($gray-lightest, 0) 0%, rgba($gray-lightest, 1) 20%)
  }

  & .row {
    margin-top: $padding-large-vertical;
    margin-bottom: $padding-large-vertical;
  }
}

.sw-Footer_Container {
  z-index: $zi-max;
  position: relative;
}
