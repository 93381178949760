.dr-Grid_Container {
  display: grid;
  grid-gap: $padding-large-vertical;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: rows;

  @media (min-width: $screen-xl-min) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.dr-Grid_Item {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: calc(100%);
  margin: 0;
}

.dr-Grid_Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dr-Grid_ImageWrapper {
  width: 100%;
  height: 100%;
  background: $gray-lightest;
}

.dr-Grid_Wrapper {
  display: flex;
  flex-wrap: wrap;
}
