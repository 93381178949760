.dn-NearRestaurants_List {
  margin-bottom: $padding-large-vertical * 2;
}

.dn-NearRestaurants_Item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $padding-large-vertical 0;
  color: $black;
  font-size: $font-size-h5;
  cursor: pointer;
}

.dn-NearRestaurants_Item-active {
  background: $black;
  color: $white;
  padding: $padding-large-vertical;
}

.dn-NearRestaurants_Title {
  font-weight: bold;
  margin-top: $padding-large-vertical * 3;
}

.dn-NearRestaurants_ItemSubText {
  font-size: $font-size-h6;
  color: $gray;
}

.dn-NearRestaurants_IteMainText {
margin-bottom: $padding-medium-vertical;
}

.dn-NearRestaurants_NoGeoLocation {
  max-width: $max-length-text;
  padding: $padding-large-vertical * 3 0;
}

.dn-NearRestaurants_NoGeoHeadline {
  font-size: $font-size-h2;
  line-height: 1.1;
  margin-bottom: $padding-large-vertical;
}

.dn-NearRestaurants_NoGeoSubline {
  color: $gray;
  font-size: $font-size-h5;
}
