.sf-Input_Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
  position: relative;
}

.sf-Input_TopWrapper {
  display: flex;
  justify-content: space-between;
}

.sf-Input_SearchWrapper {
  flex-grow: 1;
  flex-shrink: 0;
}

.sf-Input_MapWrapper {
  flex-shrink: 0;
}