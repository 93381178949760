.pr-UserCard_Wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  margin-bottom: $padding-large-vertical * 2;
}

.pr-UserCard_ActionsWrapper {
  position: absolute;
  top: $padding-large-vertical;
  right: $padding-large-vertical;
}

.pr-UserCard_AvatarImage {
  border-radius: 50%;
  width: $profile-page-pic-size;
  height: $profile-page-pic-size;
  object-fit: cover;
}

.pr-UserCard_DisplayName {
  font-size: $font-size-h2;
}

.pr-UserCard_Location {
  color: $gray;
}

.pr-UserCard_DroolAmount {
  color: $gray;
  text-align: center;
  margin-top: $padding-large-vertical;
}

.pr-UserCard_DroolValue {
  font-size: $font-size-h3;
  color: $black;
}
