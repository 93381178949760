.ft-Menu_Link {
  color: $gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1;
  padding: $padding-large-vertical * 2 $padding-large-vertical;
  border-radius: $border-radius-base $border-radius-base 0 0;
  width: 100%;

  &:hover,
  &:active,
  &:focus {
    background-color: $white;
  }
}

.ft-Menu_Link-active {
  color: $brand-color;
}

.ft-Menu_Wrapper {
  display: flex;
  margin: 0;
  justify-content: space-between;
}

.ft-Menu_LinkText {
  font-size: $font-size-sm;
  font-weight: bold;
  margin-top: $padding-medium-vertical;
}
