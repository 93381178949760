// lp = Landingpage

.lp-Account_Headline {
  font-weight: bold;
}

.lp-Account_Link {
  color: $black;
  text-decoration: underline;
}
