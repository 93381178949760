.sp-Intro_Wrapper {
  margin-bottom: $padding-large-vertical * 4;
  margin-top: $padding-large-vertical * 2;
  line-height: $line-height-base;
}

.sp-Intro_Text {
  font-size: $font-size-h1;
}

.sp-Intro_Small {
  font-size: $font-size-h4;
}
