.ta-Chip_Item {
  padding: 0 $padding-large-vertical;
  height: $height-tag-chip;
  line-height: $height-tag-chip;
  font-size: $font-size-h6;
  display: inline-block;
  color: $gray;
  border-radius: $border-radius-big;
  background-color: $gray-lighter;
  margin-bottom: $padding-medium-vertical;
  margin-right: $padding-medium-vertical;
}