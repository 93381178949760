html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family: $body-font;
  background-color: $gray-lightest;
}

#root {
  width: 100%;
  height: 100%;
}

input {
  background-color: $white !important;
  border-radius: $border-radius-base !important;
  box-shadow: $box-shadow-base !important;
  margin-bottom: $padding-large-vertical !important;
  border-bottom: 0 !important;
  padding: 0 $padding-large-vertical !important;
  box-sizing: border-box !important;
}

input:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 800ms ease-in;
}

button[disabled] {
  opacity: 0.25;
}

.error {
  color: $red;
  font-weight: bold;
  float: right;
  font-size: $font-size-h6;
}

input[type="range"] + .thumb {
  background-color: $black !important;
}