.dr-Slide_Card {
  flex-shrink: 0;
  width: 55%;
  margin-right: $padding-large-vertical;

  @media (min-width: $screen-sm-min) {
    width: 30%;
  }

  @media (min-width: $screen-lg-min) {
    width: 23%;
  }
}
