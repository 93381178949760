.dr-List_ItemWrapper {
  display: flex;
  background: $white;
  border-radius: $border-radius-base;
  box-shadow: $box-shadow-base;
  overflow: hidden;
  height: 100%;
}

.dr-List_Item-private {
  border-right: $width-border-thick solid $red;
}

.dr-List_LinkWrapper {
  display: block;
  width: 100%;
  height: 100%;
}

.dr-List_ImageContainer {
  width: 80px;
  height: 80px;
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
}

.dr-List_Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dr-List_ContentWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  padding: $padding-large-vertical;
  min-width: 0;
}

.dr-List_Title {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dr-List_Place {
  font-size: $font-size-sm;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: $padding-large-vertical;
}

.dr-List_User {
  display: flex;
  align-items: center;
  font-size: $font-size-sm;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dr-List_UserImage {
  border-radius: 50%;
  width: $header-profile-pic-size / 2;
  height: $header-profile-pic-size / 2;
  margin-right: $padding-medium-vertical;
}

.dr-List_Comment {
  margin-top: $padding-large-vertical;
  font-size: $font-size-xs;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $gray;
}

.dr-List_TextWrapper {
  color: $black;
  flex-grow: 1;
  min-width: 0;
}
