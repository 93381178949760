.bt-Button {
  background-color: $white;
  color: $black;
  border-radius: $border-radius-base;
  border: $width-border-thick solid $black;
  font-size: $font-size-h4;
  padding: $padding-large-vertical;
  flex-shrink: 0;
  flex-grow: 1;
  margin-bottom: $padding-large-vertical;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover, &:active, &:focus {
    background-color: $white;
    border-color: $black;
    cursor: pointer;
  }
}

.bt-Button_Full {
  min-width: 100%;
}

.bt-Button-primary {
  background-color: $black;
  border-color: $black;
  color: $white;

  &:hover, &:active, &:focus {
    background-color: $black;
    border-color: $black;
  }
}

.bt-Button-secondary {
  background-color: $white;
  border-color: $black;
  color: $black;
  flex-grow: 0;

  &:hover, &:active, &:focus {
    background-color: $black;
    color: $white;
    border-color: $black;
  }
}

.bt-Button_Map {
  margin-top: $padding-large-vertical * 2;
  margin-bottom: $padding-large-vertical;
  margin-left: $padding-large-vertical;
  border: none;
  background: none;
}
