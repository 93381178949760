.in-Searchfield_Wrapper {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: flex-start;
  background: $white;
  box-shadow: $box-shadow-base;
  border-radius: $border-radius-base;
  position: relative;
  width: 100%;
}

.in-Searchfield_Icon {
  padding-right: $padding-large-vertical;
}

.in-Searchfield_Input {
  width: 100% !important;
  margin-bottom: 0 !important;
  border-bottom: 0 !important;
  padding-left: $padding-large-vertical !important;
  box-shadow: none !important;
  background: none !important;
}

.in-Searchfield_OuterWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: $padding-large-vertical * 2;
  margin-bottom: $padding-large-vertical;
}
