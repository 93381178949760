// lp = Landingpage

.lp-Form_Wrapper {
  max-width: $max-width-form;
}

.lp-Form_InputWrapper {
  margin-bottom: $padding-large-vertical * 2;
}

.lp-Form_Headline {
  font-weight: bold;
}
