@import url(https://use.typekit.net/ayh8qlk.css);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@charset "UTF-8";
html {
  width: 100%;
  height: 100%; }

body {
  width: 100%;
  height: 100%;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  background-color: #FAFAFC; }

#root {
  width: 100%;
  height: 100%; }

input {
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 1px 1px 15px rgba(0, 69, 119, 0.1) !important;
  margin-bottom: 10px !important;
  border-bottom: 0 !important;
  padding: 0 10px !important;
  box-sizing: border-box !important; }

input:focus {
  border-bottom: none !important;
  box-shadow: none !important; }

.fade-enter {
  opacity: 0.01; }

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 1000ms ease-in;
  transition: opacity 1000ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit.fade-exit-active {
  opacity: 0.01;
  -webkit-transition: opacity 800ms ease-in;
  transition: opacity 800ms ease-in; }

button[disabled] {
  opacity: 0.25; }

.error {
  color: #ff0000;
  font-weight: bold;
  float: right;
  font-size: 0.85rem; }

input[type="range"] + .thumb {
  background-color: #000 !important; }

.ad-Form_DroolImage {
  position: relative;
  width: 100%;
  text-align: left;
  border-radius: 5px;
  margin-bottom: 10px; }

.ad-Form_FileUploadButton {
  width: 100%;
  background: #000;
  color: #fff;
  font-weight: 500;
  height: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden; }

.ad-Form_FileUploadButton-active {
  background: #fff;
  color: #000;
  border: 2px solid #000; }

.ad-Form_Headline {
  font-weight: bold; }

.ad-Form_ImageUploadWrapper {
  background: #000;
  border-radius: 2px;
  padding: 10px; }

.ad-Form_RadioWrapper {
  display: flex;
  justify-content: space-between; }

.ad-Form_RadioItem {
  border: 2px solid #000;
  display: flex;
  flex-grow: 1; }
  .ad-Form_RadioItem:not(:last-child) {
    border-right: 0; }

input[type="radio"]:not(:checked) + label, [type="radio"]:checked + label {
  padding-left: 0;
  padding: 10px;
  width: 100%;
  text-align: center;
  color: #000; }
  input[type="radio"]:not(:checked) + label:before, [type="radio"]:checked + label:before {
    display: none; }
  input[type="radio"]:not(:checked) + label:after, [type="radio"]:checked + label:after {
    display: none; }

input[type="radio"]:checked + label {
  background: #000;
  color: #fff;
  height: 100%;
  padding-left: 10px; }

.ad-Form_FileUploadWrapper {
  position: relative;
  overflow: hidden; }

.ad-Form_ButtonLabel {
  flex-grow: 1; }

.ad-Form_FileIcon {
  font-size: 1rem !important;
  margin-right: 10px; }

.ad-Form_FileUploadLabel {
  padding: 10px; }

.ad-Form_FileUploadWrapper [type=file] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  height: 100%;
  width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0; }

.ad-Form_ConfirmImagePreloadWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  max-height: 250px;
  border: 2px solid #ccc;
  border-radius: 10px;
  overflow: hidden; }

.ad-Form_ConfirmImagePreloadIcon {
  color: #ccc; }

.ad-Form_ConfirmImage {
  width: 100%; }

.ad-Form_ButtonSubmitLabel {
  padding-left: 10px; }

.ad-Form_Button {
  padding: 10px;
  height: auto; }

.ad-Form_Button-skip {
  background: #fff;
  color: #000;
  border: 2px solid #000; }

.ad-Form_Button-next {
  background: #000;
  color: #fff; }

.ad-Form_Button-back {
  border: 2px solid #000;
  margin-right: 10px;
  background: #fff;
  line-height: 1;
  color: #000;
  padding: 10px;
  height: auto; }

.ad-Form_InstagramWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px; }

.ad-Form_InstagramImageContainer {
  border: 2px solid #ccc;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; }
  .ad-Form_InstagramImageContainer::before {
    content: "";
    display: block;
    margin-top: 100%; }

.ad-Form_InstagramImageIcon {
  color: #ccc; }

.ad-Form_InstagramImage {
  width: 100%;
  opacity: 1;
  -webkit-transition: 0.25s opacity;
  transition: 0.25s opacity; }
  .ad-Form_InstagramImage:hover {
    opacity: 0.5;
    cursor: pointer; }
  .ad-Form_InstagramImage:active, .ad-Form_InstagramImage:focus {
    opacity: 1; }

.ad-Form_InstagramImage-active {
  border: 2px solid #000; }

.ap-App_Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }

.ap-App_Content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%; }

.bt-Button {
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  border: 2px solid #000;
  font-size: 1.25rem;
  padding: 10px;
  flex-shrink: 0;
  flex-grow: 1;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .bt-Button:hover, .bt-Button:active, .bt-Button:focus {
    background-color: #fff;
    border-color: #000;
    cursor: pointer; }

.bt-Button_Full {
  min-width: 100%; }

.bt-Button-primary {
  background-color: #000;
  border-color: #000;
  color: #fff; }
  .bt-Button-primary:hover, .bt-Button-primary:active, .bt-Button-primary:focus {
    background-color: #000;
    border-color: #000; }

.bt-Button-secondary {
  background-color: #fff;
  border-color: #000;
  color: #000;
  flex-grow: 0; }
  .bt-Button-secondary:hover, .bt-Button-secondary:active, .bt-Button-secondary:focus {
    background-color: #000;
    color: #fff;
    border-color: #000; }

.bt-Button_Map {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
  border: none;
  background: none; }

.cb-Primary {
  color: #2398ff;
  background: #fff;
  border-radius: 5px;
  border: 2px solid #2398ff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px; }
  .cb-Primary:focus, .cb-Primary:active {
    background: #fff; }

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.animated-background:focus, .animated-background:active {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: darkgray;
  background: -webkit-linear-gradient(left, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative; }

.cb-Primary-active {
  color: #fff;
  background: #2398ff;
  border-color: #2398ff;
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #2398ff;
  background: -webkit-linear-gradient(left, #2398ff 10%, #43a7ff 40%, #2398ff 20%);
  background: linear-gradient(to right, #2398ff 10%, #43a7ff 40%, #2398ff 20%);
  background-size: 800px 104px;
  position: relative; }

.cb-Icon {
  font-size: 1rem; }

.cb-Icon-active {
  color: #fff; }

.dn-Form_Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1; }

.dn-Form_Container {
  flex-grow: 1;
  display: flex;
  flex-direction: column; }

.dn-Form_InnerForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1; }

.dn-Form_InputText {
  font-weight: normal; }

.dn-Form_InnerContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }

.dn-Form_FormArea {
  flex-grow: 1; }

.dn-Form_ButtonArea {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  margin-top: 20px; }

.dn-Form_HeroIcon {
  font-size: 12rem;
  width: 100%;
  text-align: center; }

.dn-Form_InputLabel {
  display: block;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 2px; }

.dn-Form_InputWrapper {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 15px rgba(0, 69, 119, 0.1);
  margin-bottom: 10px; }

.dn-Form_InputSearchIcon {
  padding-right: 10px; }

.dn-Form_InputLocationIcon {
  font-size: 1.25rem;
  margin-right: 10px;
  color: #777; }

.dn-Form_InputLocationIcon-active {
  color: #2398ff; }

.dn-Form_InputRed {
  font-size: 0.7rem;
  color: #ff0000;
  margin-bottom: 10px; }

.dn-Form_PlaceSelect {
  width: 100% !important;
  margin-bottom: 0 !important;
  border-bottom: 0 !important;
  padding-left: 10px !important;
  box-shadow: none !important; }

.dn-Form_Textarea {
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 15px rgba(0, 69, 119, 0.1);
  margin-bottom: 10px;
  padding: 10px;
  height: auto; }

.dn-Input_Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 10px; }

.dn-Input_Autocomplete {
  border-radius: 0 0 5px;
  border-top: 0;
  color: #777;
  font-size: 0.85rem;
  overflow: hidden;
  width: 100%;
  padding: 10px 0;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  position: absolute;
  top: 100%;
  background: #fff;
  box-shadow: 1px 1px 15px rgba(0, 69, 119, 0.1);
  border-top: #fff; }

.dn-Input_Item {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer; }

.dn-Input_Item-primary {
  color: #777; }

.dn-Input_Item-secondary {
  color: #7A7A7A;
  text-align: right; }

.dn-Input_Item-selected {
  background-color: #FAFAFC;
  color: #000; }

.dn-Input_Textfield {
  margin-bottom: 0 !important;
  box-shadow: none !important; }

.dn-Input_ValueWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 100%; }

.dn-Input_DishInput {
  margin-bottom: 0 !important; }

.dn-Input_AutocompleteGroup {
  margin: 10px 0; }

.dn-Input_AutoGroupHeader {
  color: #4BD292;
  font-weight: bold;
  font-size: 0.85rem;
  padding: 5px 10px; }

.dn-NearRestaurants_List {
  margin-bottom: 20px; }

.dn-NearRestaurants_Item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  color: #000;
  font-size: 1rem;
  cursor: pointer; }

.dn-NearRestaurants_Item-active {
  background: #000;
  color: #fff;
  padding: 10px; }

.dn-NearRestaurants_Title {
  font-weight: bold;
  margin-top: 30px; }

.dn-NearRestaurants_ItemSubText {
  font-size: 0.85rem;
  color: #777; }

.dn-NearRestaurants_IteMainText {
  margin-bottom: 5px; }

.dn-NearRestaurants_NoGeoLocation {
  max-width: 30ch;
  padding: 30px 0; }

.dn-NearRestaurants_NoGeoHeadline {
  font-size: 1.85rem;
  line-height: 1.1;
  margin-bottom: 10px; }

.dn-NearRestaurants_NoGeoSubline {
  color: #777;
  font-size: 1rem; }

.dn-Review_Image {
  width: 100%; }

.dn-SelectedPlace_Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 15px rgba(0, 69, 119, 0.1);
  padding: 15px;
  color: #000;
  font-size: 1rem;
  border: 2px solid #fff;
  align-items: center; }

.dn-SelectedPlace_Headline {
  font-size: 0.6rem;
  color: #777;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px; }

.dn-SelectedPlace_Name {
  max-width: 30ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.dn-SelectedPlace_Action {
  font-size: 1.5rem;
  padding: 10px;
  color: #777;
  border-radius: 5px; }
  .dn-SelectedPlace_Action:hover, .dn-SelectedPlace_Action:focus, .dn-SelectedPlace_Action:active {
    background-color: #e4e4e4;
    cursor: pointer; }

.do-Actions_Wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 20px; }

.do-Actions_RightWrapper {
  margin-left: auto;
  display: flex; }

.do-Intro_Headline {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0; }

.do-Intro_Subline {
  font-size: 0.85rem; }

.do-Toggle_Wrapper {
  display: flex;
  flex-shrink: 0; }

.do-Toggle_Button {
  background: #fff;
  padding: 10px 12px;
  color: #4BD292;
  border: none;
  font-size: 0.85rem;
  margin-right: 5px;
  border-radius: 20px;
  box-shadow: 1px 1px 15px rgba(0, 69, 119, 0.1); }

.do-Toggle_Button-active {
  background: #4BD292;
  color: #fff; }

.dr-Big_Wrapper {
  display: flex;
  flex-wrap: wrap; }

.dr-Big_Card {
  margin-left: 0 !important;
  margin-bottom: 0; }

.dr-Big_InnerCard {
  margin: 0;
  margin-bottom: 20px;
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 15px rgba(0, 69, 119, 0.1);
  overflow: hidden; }

.dr-Big_Item-private {
  border-right: 2px solid #ff0000; }

.dr-Big_ImageWrapper {
  position: relative; }

.dr-Big_TextWrapper {
  flex-grow: 1;
  min-width: 0; }

.dr-Big_ContentWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #000;
  padding: 10px; }

.dr-Big_Title {
  font-weight: bold;
  line-height: 1;
  margin-bottom: 5px; }

.dr-Big_Place {
  font-size: 0.6rem;
  margin-bottom: 5px; }

.dr-Big_User {
  font-size: 0.6rem; }

.dr-Big_Comment {
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.6rem;
  color: #777; }

.dr-Big_Image {
  width: 100%;
  display: block; }
  @media (min-width: 768px) {
    .dr-Big_Image {
      object-fit: cover;
      height: 150px; } }

.dr-Slide_Image {
  object-fit: cover;
  height: 150px; }

.dr-Detail_ItemWrapper {
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 1px 1px 15px rgba(0, 69, 119, 0.1); }
  @media (min-width: 768px) {
    .dr-Detail_ItemWrapper {
      flex-direction: row; } }

.dr-Detail_ActionsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative; }

.dr-Detail_Options {
  flex-shrink: 0; }

.dr-Detail_Time {
  flex-grow: 1;
  font-size: 0.85rem;
  color: #777; }

.dr-Detail_DroolImage {
  position: relative;
  width: 100%;
  text-align: left;
  border-radius: 5px; }

.dr-Detail_ContentWrapper {
  display: flex; }
  @media (min-width: 768px) {
    .dr-Detail_ContentWrapper {
      width: 50%;
      order: 1;
      padding: 30px; } }

.dr-Detail_TextWrapper {
  flex-grow: 1; }

.dr-Detail_PlaceSign {
  margin-right: 10px; }

.dr-Detail_PlaceLink {
  color: #000; }

.dr-Detail_Name {
  font-size: 2rem;
  margin: 0;
  margin-bottom: 10px; }

.dr-Detail_Type {
  font-size: 1rem;
  color: #777;
  margin: 0;
  margin-bottom: 10px; }

.dr-Detail_TypeLink {
  color: #000;
  font-size: 0.7rem; }

.dr-Detail_Comment {
  font-size: 1rem;
  padding-left: 40px;
  color: #777;
  position: relative;
  margin: 20px 0;
  white-space: pre-line; }
  .dr-Detail_Comment::before {
    content: "\201D";
    font-family: sans-serif;
    font-size: 100px;
    color: #ccc;
    position: absolute;
    left: 0;
    line-height: 0.75;
    pointer-events: none; }

.dr-Detail_CommentWrapper {
  margin-top: 5px; }

.dr-Detail_Comment-positive {
  color: #4BD292;
  font-size: 1rem;
  margin-right: 10px; }

.dr-Detail_Comment-negative {
  color: #ff0000;
  font-size: 1rem;
  margin-right: 10px; }

.dr-Detail_TagsWrapper {
  margin-bottom: 20px; }

.dr-Detail_Rating {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
  background: #fff;
  padding: 10px 20px;
  border-radius: 20px; }

.dr-Detail_DroolImageWrapper {
  position: relative; }
  @media (min-width: 768px) {
    .dr-Detail_DroolImageWrapper {
      width: 50%;
      order: 0; } }

.dr-Detail_RatingValue {
  font-size: 1.5rem;
  color: #000;
  font-weight: bold; }

.dr-Detail_User {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }

.dr-Detail_UserImage {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 5px; }

.dr-Detail_UserName {
  font-size: 0.85rem;
  color: #000;
  align-self: normal;
  line-height: 1; }

.dr-Detail_DeleteWrapper {
  margin-top: 10px;
  width: 100%;
  text-align: center; }

.dr-Detail_DeleteButton {
  color: #777;
  font-weight: bold;
  background: none;
  border: 0; }

.dr-Detail_PrivateFlag {
  color: #ff0000;
  font-size: 1rem; }
  .dr-Detail_PrivateFlag a {
    color: #ff0000;
    text-decoration: underline; }

.dr-DraftList_Headline {
  font-size: 0.6rem;
  color: #777;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px; }

.dr-DraftList_Wrapper {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  overflow-x: auto; }

.dr-DraftList_Item {
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-right: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 15px rgba(0, 69, 119, 0.1);
  padding: 15px;
  color: #000;
  font-size: 0.6rem;
  flex-shrink: 0;
  border: 2px solid #fff; }
  .dr-DraftList_Item:hover {
    cursor: pointer; }
  .dr-DraftList_Item:focus {
    border: 2px solid #ccc;
    background: #fff; }

.dr-DraftList_Name {
  font-weight: bold;
  font-size: 0.85rem;
  max-width: 20ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.dr-DraftList_Place {
  color: #777;
  max-width: 20ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.dr-Grid_Container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: rows; }
  @media (min-width: 1280px) {
    .dr-Grid_Container {
      grid-template-columns: repeat(4, 1fr); } }

.dr-Grid_Item {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: calc(100%);
  margin: 0; }

.dr-Grid_Image {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.dr-Grid_ImageWrapper {
  width: 100%;
  height: 100%;
  background: #FAFAFC; }

.dr-Grid_Wrapper {
  display: flex;
  flex-wrap: wrap; }

.dr-List_ItemWrapper {
  display: flex;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 15px rgba(0, 69, 119, 0.1);
  overflow: hidden;
  height: 100%; }

.dr-List_Item-private {
  border-right: 2px solid #ff0000; }

.dr-List_LinkWrapper {
  display: block;
  width: 100%;
  height: 100%; }

.dr-List_ImageContainer {
  width: 80px;
  height: 80px;
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0; }

.dr-List_Image {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.dr-List_ContentWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  padding: 10px;
  min-width: 0; }

.dr-List_Title {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.dr-List_Place {
  font-size: 0.7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px; }

.dr-List_User {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.dr-List_UserImage {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 5px; }

.dr-List_Comment {
  margin-top: 10px;
  font-size: 0.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #777; }

.dr-List_TextWrapper {
  color: #000;
  flex-grow: 1;
  min-width: 0; }

.dr-PlaceList_Wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.dr-PlaceList_Item {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 15px rgba(0, 69, 119, 0.1);
  padding: 15px;
  color: #000; }

.dr-PlaceList_Title {
  font-size: 1.5rem;
  line-height: 1; }

.dr-PlaceList_Count {
  color: #7A7A7A;
  font-size: 0.85rem; }

.dr-Shared_Rating {
  font-size: 1rem; }

.dr-Shared_RatingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }

.dr-Shared_RatingContainer {
  display: flex;
  align-items: center;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  background: #4BD292;
  border-radius: 5px;
  padding: 5px;
  font-size: 1rem;
  white-space: nowrap; }

.dr-Shared_Rating-4 {
  background-color: #a2e732; }

.dr-Shared_Rating-3 {
  background-color: #f7d828; }

.dr-Shared_Rating-2 {
  background-color: #ff9900; }

.dr-Shared_Rating-1 {
  background-color: #ff0000; }

.dr-Shared_RatingCount {
  color: #777;
  font-size: 0.7rem; }

.dr-Slide_Card {
  flex-shrink: 0;
  width: 55%;
  margin-right: 10px; }
  @media (min-width: 540px) {
    .dr-Slide_Card {
      width: 30%; } }
  @media (min-width: 1024px) {
    .dr-Slide_Card {
      width: 23%; } }

.dr-TagList_Wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.dr-TagList_Item {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 15px rgba(0, 69, 119, 0.1);
  padding: 15px;
  color: #000; }

.dr-TagList_Title {
  font-size: 1.5rem;
  line-height: 1; }

.dr-TagList_Count {
  color: #7A7A7A;
  font-size: 0.85rem; }

.dv-Information_Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }

.dv-Stats_Wrapper {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  min-width: 50px;
  box-shadow: 1px 1px 15px rgba(0, 69, 119, 0.1);
  text-align: center;
  line-height: 1; }

.dv-Stats_Number {
  font-size: 1.5rem;
  color: #000;
  font-weight: bold;
  margin-bottom: 5px; }

.dv-Stats_Subline {
  color: #777;
  font-size: 0.85rem; }

.dv-Stats_TagsWrapper {
  margin-left: 10px; }

.ex-Drools_Wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto; }

.ex-Combined_Headline {
  font-weight: bold;
  font-size: 1.25rem; }

.ft-Menu_Link {
  color: #777;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1;
  padding: 20px 10px;
  border-radius: 5px 5px 0 0;
  width: 100%; }
  .ft-Menu_Link:hover, .ft-Menu_Link:active, .ft-Menu_Link:focus {
    background-color: #fff; }

.ft-Menu_Link-active {
  color: #4BD292; }

.ft-Menu_Wrapper {
  display: flex;
  margin: 0;
  justify-content: space-between; }

.ft-Menu_LinkText {
  font-size: 0.7rem;
  font-weight: bold;
  margin-top: 5px; }

.hd-Header_NavBarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px; }

.hd-Header_LogoContainer {
  flex-grow: 1;
  line-height: 1; }

.hd-Header_MenuContainer {
  order: 2; }

.hd-Header_Navbar {
  color: #000;
  box-shadow: none; }
  .hd-Header_Navbar a {
    color: #000; }

.hd-Header_Logo {
  font-size: 1.25rem; }

.hd-Header_Profile {
  display: flex;
  align-items: center; }

.hd-Header_ProfilePicture {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px; }

.hd-Header_Link {
  margin-right: -15px; }

nav ul li:not(:last-child) {
  padding: 0 15px; }

.hd-Header_Back {
  font-size: 0.7rem;
  font-weight: bold;
  padding-left: 0; }

.hd-TitleHeader_Back {
  font-size: 1.85rem;
  background: none;
  border: 0; }
  .hd-TitleHeader_Back:hover, .hd-TitleHeader_Back:active, .hd-TitleHeader_Back:focus {
    background: none;
    border: 0; }

.hd-TitleHeader_TitleWrapper {
  display: flex;
  flex-direction: column;
  text-align: right;
  flex-grow: 1; }

.hd-TitleHeader_Title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px; }

.hd-TitleHeader_Subtitle {
  font-size: 0.85rem;
  color: #777;
  margin: 0; }

.hd-TitleHeader_Link {
  text-decoration: underline;
  cursor: pointer; }

.in-Searchfield_Wrapper {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: flex-start;
  background: #fff;
  box-shadow: 1px 1px 15px rgba(0, 69, 119, 0.1);
  border-radius: 5px;
  position: relative;
  width: 100%; }

.in-Searchfield_Icon {
  padding-right: 10px; }

.in-Searchfield_Input {
  width: 100% !important;
  margin-bottom: 0 !important;
  border-bottom: 0 !important;
  padding-left: 10px !important;
  box-shadow: none !important;
  background: none !important; }

.in-Searchfield_OuterWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px; }

.lo-Wrapper {
  position: absolute;
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0;
  -webkit-animation: fadeInOut 3s infinite;
          animation: fadeInOut 3s infinite; }

.lo-Wrapper_Icon {
  width: 25%;
  height: auto;
  margin-bottom: 10px;
  max-width: 200px; }

@-webkit-keyframes fadeInOut {
  0%,
  100% {
    opacity: 0; }
  50% {
    opacity: 1; } }

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0; }
  50% {
    opacity: 1; } }

.lp-Account_Headline {
  font-weight: bold; }

.lp-Account_Link {
  color: #000;
  text-decoration: underline; }

.lp-Form_Wrapper {
  max-width: 300px; }

.lp-Form_InputWrapper {
  margin-bottom: 20px; }

.lp-Form_Headline {
  font-weight: bold; }

.lp-Landing_Wrapper {
  display: flex;
  flex-direction: column; }
  @media (min-width: 540px) {
    .lp-Landing_Wrapper {
      align-items: center;
      flex-direction: row;
      height: 100%; }
      .lp-Landing_Wrapper::before {
        content: "";
        background: #fff;
        width: 35%;
        height: 100%;
        position: absolute;
        right: 0;
        z-index: -1; } }

.lp-Landing_ImageWrapper {
  flex-basis: 100%;
  order: 2;
  margin-top: 30px; }
  @media (min-width: 540px) {
    .lp-Landing_ImageWrapper {
      display: flex;
      position: absolute;
      right: -170px;
      margin-top: 0; } }
  @media (min-width: 768px) {
    .lp-Landing_ImageWrapper {
      display: flex;
      flex-basis: 50%;
      position: relative;
      right: auto; } }

.lp-Landing_TextWrapper {
  flex-basis: 100%; }
  @media (min-width: 540px) {
    .lp-Landing_TextWrapper {
      flex-basis: 50%; } }

.lp-Landing_ActionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }

.lp-Landing_InputFields {
  margin-bottom: 20px; }

.lp-Landing_Form {
  max-width: 300px; }

.lp-Landing_Image {
  width: 100%;
  max-width: 350px;
  margin: auto;
  -webkit-filter: drop-shadow(0 0 25px rgba(0, 0, 0, 0.05));
          filter: drop-shadow(0 0 25px rgba(0, 0, 0, 0.05)); }
  @media (min-width: 540px) {
    .lp-Landing_Image {
      max-width: 400px; } }

.lp-Landing_IntroLogo {
  font-size: 1.5rem;
  font-weight: bold; }

.lp-Landing_IntroHeadline {
  font-size: 2rem;
  font-weight: normal;
  max-width: 15ch; }
  @media (min-width: 540px) {
    .lp-Landing_IntroHeadline {
      font-size: 4rem;
      padding-right: 20px; } }

.lp-Landing_IntroText {
  font-size: 1.25rem; }
  @media (min-width: 540px) {
    .lp-Landing_IntroText {
      font-size: 1.5rem;
      margin-bottom: 50px;
      max-width: 30ch; } }

.lp-Landing_Button {
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  -webkit-transition: color 0.25s;
  transition: color 0.25s;
  background-color: transparent; }
  .lp-Landing_Button::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: #000;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    -webkit-transition: -webkit-transform 0.5s;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s; }
  .lp-Landing_Button:hover, .lp-Landing_Button:focus, .lp-Landing_Button:active {
    color: #fff;
    background-color: transparent; }
    .lp-Landing_Button:hover::before, .lp-Landing_Button:focus::before, .lp-Landing_Button:active::before {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      -webkit-transition: -webkit-transform 0.25s;
      transition: -webkit-transform 0.25s;
      transition: transform 0.25s;
      transition: transform 0.25s, -webkit-transform 0.25s; }

.lp-Landing_Link {
  display: block; }

.mo-Options_Wrapper {
  background: white;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 300px;
  position: relative; }
  .mo-Options_Wrapper:focus {
    outline: 0; }

.mo-Options_InputField {
  border: 1px solid #ccc !important;
  width: 100%; }
  .mo-Options_InputField:focus {
    border-bottom: 1px solid #ccc !important; }

.mo-Options_ItemWrapper {
  margin-bottom: 10px; }

.mo-Options_Range {
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important; }

.mo-Options_Overlay {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%; }

.mo-Options_Headline {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1; }

.mo-Options_CloseButton {
  font-size: 1.85rem;
  background: none;
  border: 0;
  position: absolute;
  top: 10px;
  right: 10px; }
  .mo-Options_CloseButton:active, .mo-Options_CloseButton:focus, .mo-Options_CloseButton:hover {
    background: none;
    border: 0; }

.mo-Options_Button {
  background: none;
  border: 0; }
  .mo-Options_Button:active, .mo-Options_Button:focus, .mo-Options_Button:hover {
    background: none;
    border: 0; }

.mo-Options_HeadlineWrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 10px; }

.mo-Options_HeadlineIcon {
  font-size: 1.85rem;
  margin: 0;
  margin-right: 10px; }

.mo-Options_Subheadline {
  font-size: 0.85rem;
  color: #777;
  font-weight: bold;
  margin: 0 0 5px; }

.mo-Options_ButtonWrapper {
  margin-top: 20px; }

.mo-Toggle_Button {
  background: none;
  padding: 10px 5px;
  color: #ccc;
  flex-grow: 1;
  border: none;
  font-size: 0.85rem;
  line-height: 1; }
  .mo-Toggle_Button:focus {
    background: none;
    color: #4BD292; }

.mo-Toggle_Button-active {
  color: #4BD292; }

.mo-Toggle_Wrapper {
  flex-shrink: 0; }

.pr-Edit_AvatarWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px; }

.pr-Edit_AvatarImage {
  margin-right: 10px; }

.pr-Edit_AvatarImageEdit {
  text-decoration: underline;
  color: #777; }

.pr-Edit_AvatarImageDelete {
  text-decoration: underline;
  color: #777;
  padding-left: 10px; }

.pr-Edit_Input {
  border: 1px solid #ccc !important;
  width: 100%; }
  .pr-Edit_Input:focus {
    border-bottom: 1px solid #ccc !important; }

.pr-Edit_InputWrapper {
  margin-bottom: 10px;
  width: 100%; }

.pr-Edit_Form {
  max-width: 300px;
  margin: auto; }

.pr-UserCard_Wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px; }

.pr-UserCard_ActionsWrapper {
  position: absolute;
  top: 10px;
  right: 10px; }

.pr-UserCard_AvatarImage {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover; }

.pr-UserCard_DisplayName {
  font-size: 1.85rem; }

.pr-UserCard_Location {
  color: #777; }

.pr-UserCard_DroolAmount {
  color: #777;
  text-align: center;
  margin-top: 10px; }

.pr-UserCard_DroolValue {
  font-size: 1.5rem;
  color: #000; }

.sf-Input_Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
  position: relative; }

.sf-Input_TopWrapper {
  display: flex;
  justify-content: space-between; }

.sf-Input_SearchWrapper {
  flex-grow: 1;
  flex-shrink: 0; }

.sf-Input_MapWrapper {
  flex-shrink: 0; }

.sp-Intro_Wrapper {
  margin-bottom: 40px;
  margin-top: 20px;
  line-height: 1.2; }

.sp-Intro_Text {
  font-size: 2rem; }

.sp-Intro_Small {
  font-size: 1.25rem; }

.sw-Footer_Spacer {
  height: 75px; }

.sw-Footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #FAFAFC;
  z-index: 1000; }
  .sw-Footer::before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 70px;
    width: 100%;
    background-image: -webkit-linear-gradient(top, rgba(250, 250, 252, 0) 0%, #fafafc 20%);
    background-image: linear-gradient(to bottom, rgba(250, 250, 252, 0) 0%, #fafafc 20%); }
  .sw-Footer .row {
    margin-top: 10px;
    margin-bottom: 10px; }

.sw-Footer_Container {
  z-index: 1001;
  position: relative; }

.ta-Chip_Item {
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  font-size: 0.85rem;
  display: inline-block;
  color: #777;
  border-radius: 10px;
  background-color: #e4e4e4;
  margin-bottom: 5px;
  margin-right: 5px; }

.to-Dropdown_OptionIcon {
  border: 0;
  background: none; }
  .to-Dropdown_OptionIcon:hover, .to-Dropdown_OptionIcon:active, .to-Dropdown_OptionIcon:focus {
    background: none; }

.to-Dropdown_OptionItem {
  display: block;
  padding: 10px;
  color: #7A7A7A;
  font-size: 0.85rem;
  background: none;
  border: 0; }
  .to-Dropdown_OptionItem:not(:last-child) {
    border-bottom: 1px solid #ccc; }
  .to-Dropdown_OptionItem:hover, .to-Dropdown_OptionItem:active, .to-Dropdown_OptionItem:focus {
    color: #000;
    background: #FAFAFC;
    cursor: pointer; }

.to-Dropdown_OptionsWrapper {
  position: absolute;
  top: 30px;
  z-index: 1001;
  margin-bottom: 50px;
  right: 0;
  display: none;
  background: #fff;
  padding: 5px 0;
  opacity: 0;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  border-radius: 5px;
  border: 1px solid #ccc; }

.to-Dropdown_OptionsWrapper-visible {
  display: block;
  opacity: 1; }

select {
  display: block !important; }

