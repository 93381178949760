.dr-Big_Wrapper {
  display: flex;
  flex-wrap: wrap;
}

.dr-Big_Card {
  margin-left: 0 !important; //Breaking due to materialize having the upper hand
  margin-bottom: 0;
}

.dr-Big_InnerCard {
  margin: 0;
  margin-bottom: $padding-large-vertical * 2;
  position: relative;
  background: $white;
  border-radius: $border-radius-base;
  box-shadow: $box-shadow-base;
  overflow: hidden;
}

.dr-Big_Item-private {
  border-right: $width-border-thick solid $red;
}

.dr-Big_ImageWrapper {
  position: relative;
}

.dr-Big_TextWrapper {
  flex-grow: 1;
  min-width: 0;
}

.dr-Big_ContentWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $black;
  padding: $padding-large-vertical;
}

.dr-Big_Title {
  font-weight: bold;
  line-height: 1;
  margin-bottom: $padding-medium-vertical;
}

.dr-Big_Place {
  font-size: $font-size-xs;
  margin-bottom: $padding-large-vertical / 2;
}

.dr-Big_User {
  font-size: $font-size-xs;
}

.dr-Big_Comment {
  margin-top: $padding-large-vertical;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $font-size-xs;
  color: $gray;
}

.dr-Big_Image {
  width: 100%;
  display: block;

  @media (min-width: $screen-md-min) {
    object-fit: cover;
    height: 150px;
  }
}

.dr-Slide_Image {
  object-fit: cover;
  height: 150px;
}
