.ad-Form_DroolImage {
  position: relative;
  width: 100%;
  text-align: left;
  border-radius: $border-radius-base;
  margin-bottom: $padding-large-vertical;
}

.ad-Form_FileUploadButton {
  width: 100%;
  background: $black;
  color: $white;
  font-weight: $font-weight-bold;
  height: auto;
  padding: $padding-large-vertical;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.ad-Form_FileUploadButton-active {
  background: $white;
  color: $black;
  border: $width-border-thick solid $black;
}

.ad-Form_Headline {
  font-weight: bold;
}

.ad-Form_ImageUploadWrapper {
  background: $black;
  border-radius: $width-border-thick;
  padding: $padding-large-vertical;
}

.ad-Form_RadioWrapper {
  display: flex;
  justify-content: space-between;
}

.ad-Form_RadioItem {
  border: $width-border-thick solid $black;
  display: flex;
  flex-grow: 1;
  &:not(:last-child) {
    border-right: 0;
  }

}

input[type="radio"]:not(:checked) + label, [type="radio"]:checked + label {
  padding-left: 0;
  padding: $padding-large-vertical;
  width: 100%;
  text-align: center;
  color: $black;
  &:before {
    display: none;
  }

  &:after {
    display: none;
  }

}

input[type="radio"]:checked + label {
  background: $black;
  color: $white;
  height: 100%;
  padding-left: $padding-large-vertical;
}

.ad-Form_FileUploadWrapper {
  position: relative;
  overflow: hidden;
}

.ad-Form_ButtonLabel {
  flex-grow: 1;
}

.ad-Form_FileIcon {
  font-size: $font-size-h5 !important; // overwriting materialize
  margin-right: $padding-large-vertical;
}

.ad-Form_FileUploadLabel {
  padding: $padding-large-vertical;
}

.ad-Form_FileUploadWrapper [type=file] {
    cursor: inherit;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    height: 100%;
    width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
}

.ad-Form_ConfirmImagePreloadWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  max-height: 250px;
  border: $width-border-thick solid $gray-light;
  border-radius: $border-radius-big;
  overflow: hidden;
}

.ad-Form_ConfirmImagePreloadIcon {
  color: $gray-light;
}

.ad-Form_ConfirmImage {
  width: 100%;
}

.ad-Form_ButtonSubmitLabel {
  padding-left: $padding-large-vertical;
}

.ad-Form_Button {
  padding: $padding-large-vertical;
  height: auto;
}

.ad-Form_Button-skip {
  background: $white;
  color: $black;
  border: $width-border-thick solid $black;
}

.ad-Form_Button-next {
  background: $black;
  color: $white;
}

.ad-Form_Button-back {
  border: $width-border-thick solid $black;
  margin-right: $padding-large-vertical;
  background: $white;
  line-height: 1;
  color: $black;
  padding: $padding-large-vertical;
  height: auto;
}

.ad-Form_InstagramWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: $padding-large-vertical;
}

.ad-Form_InstagramImageContainer {
  border: $width-border-thick solid $gray-light;
  border-radius: $border-radius-big;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow:hidden;

  &::before {
    content: "";
    display: block;
    margin-top: 100%;
  }
}

.ad-Form_InstagramImageIcon {
  color: $gray-light;
}

.ad-Form_InstagramImage {
   width: 100%;
   opacity: 1;
   transition: $transition-delay-short opacity;

   &:hover {
     opacity: 0.5;
     cursor: pointer;
   }

   &:active, &:focus {
     opacity: 1;
   }
}

.ad-Form_InstagramImage-active {
  border: $width-border-thick solid $black;
}
