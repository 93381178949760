.ap-App_Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.ap-App_Content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}
