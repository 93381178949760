// lp = Landing

.lp-Landing_Wrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-sm-min) {
    align-items: center;
    flex-direction: row;
    height: 100%;

    &::before {
      content: "";
      background: $white;
      width: 35%;
      height: 100%;
      position: absolute;
      right: 0;
      z-index: -1;
    }
  }
}

.lp-Landing_ImageWrapper {
  flex-basis: 100%;
  order: 2;
  margin-top: $padding-large-vertical * 3;

  @media (min-width: $screen-sm-min) {
    display: flex;
    position: absolute;
    right: -170px;
    margin-top: 0;
  }

  @media (min-width: $screen-md-min) {
    display: flex;
    flex-basis: 50%;
    position: relative;
    right: auto;
  }
}

.lp-Landing_TextWrapper {
  flex-basis: 100%;

  @media (min-width: $screen-sm-min) {
    flex-basis: 50%;
  }
}

.lp-Landing_ActionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.lp-Landing_InputFields {
  margin-bottom: $padding-large-vertical * 2;
}

.lp-Landing_Form {
  max-width: $max-width-form;
}

.lp-Landing_Image {
  width: 100%;
  max-width: 350px;
  margin: auto;
  filter: drop-shadow($drop-shadow-large);

  @media (min-width: $screen-sm-min) {
    max-width: 400px;
  }
}

.lp-Landing_IntroLogo {
  font-size: $font-size-h3;
  font-weight: bold;
}

.lp-Landing_IntroHeadline {
  font-size: $font-size-h1;
  font-weight: normal;
  max-width: 15ch;

  @media (min-width: $screen-sm-min) {
    font-size: $font-size-xl;
    padding-right: $padding-large-vertical * 2;
  }
}

.lp-Landing_IntroText {
  font-size: $font-size-h4;

  @media (min-width: $screen-sm-min) {
    font-size: $font-size-h3;
    margin-bottom: $padding-xlarge-vertical;
    max-width: 30ch;
  }
}

.lp-Landing_Button {
  margin-bottom: $padding-large-vertical;
  position: relative;
  overflow: hidden;
  transition: color $transition-delay-short;
  background-color: transparent;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: $black;
    transform: translate3d(-100%, 0, 0);
    transition: transform $transition-delay-short * 2;
  }

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: transparent;

    &::before {
      transform: translate3d(0, 0, 0);
      transition: transform $transition-delay-short;
    }
  }
}

.lp-Landing_Link {
  display: block;
}