.dn-SelectedPlace_Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: $white;
  border-radius: $border-radius-base;
  box-shadow: $box-shadow-base;
  padding: $padding-medium-vertical * 3;
  color: $black;
  font-size: $font-size-h5;
  border: $width-border-thick solid $white;
  align-items: center;
}

.dn-SelectedPlace_Headline {
  font-size: $font-size-xs;
  color: $gray;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: $padding-medium-vertical;
}

.dn-SelectedPlace_Name {
  max-width: 30ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dn-SelectedPlace_Action {
  font-size: $font-size-h3;
  padding: $padding-large-vertical;
  color: $gray;
  border-radius: $border-radius-base;

  &:hover, &:focus, &:active {
    background-color: $gray-lighter;
    cursor: pointer;
  }
}