.dr-Detail_ItemWrapper {
  background: $white;
  padding: $padding-large-vertical * 2;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-base;
  box-shadow: $box-shadow-base;

  @media (min-width: $screen-md-min) {
    flex-direction: row;
  }
}

.dr-Detail_ActionsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.dr-Detail_Options {
  flex-shrink: 0;
}

.dr-Detail_Time {
  flex-grow: 1;
  font-size: $font-size-h6;
  color: $gray;
}

.dr-Detail_DroolImage {
  position: relative;
  width: 100%;
  text-align: left;
  border-radius: $border-radius-base;
}

.dr-Detail_ContentWrapper {
  display: flex;

  @media (min-width: $screen-md-min) {
    width: 50%;
    order: 1;
    padding: $padding-large-vertical * 3;
  }
}

.dr-Detail_TextWrapper {
  flex-grow: 1;
}

.dr-Detail_PlaceSign {
  margin-right: $padding-large-vertical;
}

.dr-Detail_PlaceLink {
  color: $black;
}

.dr-Detail_Name {
  font-size: $font-size-h1;
  margin: 0;
  margin-bottom: $padding-large-vertical;
}

.dr-Detail_Type {
  font-size: $font-size-h5;
  color: $gray;
  margin: 0;
  margin-bottom: $padding-large-vertical;
}

.dr-Detail_TypeLink {
  color: $black;
  font-size: $font-size-sm;
}

.dr-Detail_Comment {
  font-size: $font-size-h5;
  padding-left: $padding-large-vertical * 4;
  color: $gray;
  position: relative;
  margin: $padding-large-vertical * 2 0;
  white-space: pre-line;

  &::before {
    content: "\201D";
    font-family: sans-serif;
    font-size: 100px;
    color: $gray-light;
    position: absolute;
    left: 0;
    line-height: 0.75;
    pointer-events: none;
  }
}

.dr-Detail_CommentWrapper {
  margin-top: $padding-medium-vertical;
}

.dr-Detail_Comment-positive {
  color: $green;
  font-size: $font-size-h5;
  margin-right: $padding-large-vertical;
}

.dr-Detail_Comment-negative {
  color: $red;
  font-size: $font-size-h5;
  margin-right: $padding-large-vertical;
}

.dr-Detail_TagsWrapper {
  margin-bottom: $padding-large-vertical * 2;
}

.dr-Detail_Rating {
  position: absolute;
  right: $padding-large-vertical;
  top: $padding-large-vertical;
  z-index: $zi-high;
  background: $white;
  padding: $padding-large-vertical $padding-large-vertical * 2;
  border-radius: $border-radius-medium;
}

.dr-Detail_DroolImageWrapper {
  position: relative;

  @media (min-width: $screen-md-min) {
    width: 50%;
    order: 0;
  }
}

.dr-Detail_RatingValue {
  font-size: $font-size-h3;
  color: $black;
  font-weight: bold;
}

.dr-Detail_User {
  display: flex;
  align-items: center;
  margin-bottom: $padding-large-vertical;
}

.dr-Detail_UserImage {
  border-radius: 50%;
  width: $header-profile-pic-size / 2;
  height: $header-profile-pic-size / 2;
  margin-right: $padding-medium-vertical;
}

.dr-Detail_UserName {
  font-size: $font-size-h6;
  color: $black;
  align-self: normal;
  line-height: 1;
}

.dr-Detail_DeleteWrapper {
  margin-top: $padding-large-vertical;
  width: 100%;
  text-align: center;
}

.dr-Detail_DeleteButton {
  color: $gray;
  font-weight: bold;
  background: none;
  border: 0;
}

.dr-Detail_PrivateFlag {
  color: $red;
  font-size: $font-size-h5;

  a {
    color: $red;
    text-decoration: underline;
  }
}