.dv-Stats_Wrapper {
  background: $white;
  padding: $padding-large-vertical;
  border-radius: $border-radius-base;
  min-width: $padding-large-vertical * 5;
  box-shadow: $box-shadow-base;
  text-align: center;
  line-height: 1;
}

.dv-Stats_Number {
  font-size: $font-size-h3;
  color: $black;
  font-weight: bold;
  margin-bottom: $padding-large-vertical / 2;
}

.dv-Stats_Subline {
  color: $gray;
  font-size: $font-size-h6;
}

.dv-Stats_TagsWrapper {
  margin-left: $padding-large-vertical;
}