.dr-Shared_Rating {
  font-size: $font-size-h5;
}

.dr-Shared_RatingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.dr-Shared_RatingContainer {
  display: flex;
  align-items: center;
  font-weight: bold;
  line-height: 1;
  color: $white;
  background: $green;
  border-radius: $border-radius-base;
  padding: $padding-medium-vertical;
  font-size: $font-size-h5;
  white-space: nowrap;
}

.dr-Shared_Rating-4 {
  background-color: $rating4;
}

.dr-Shared_Rating-3 {
  background-color: $rating3;
}

.dr-Shared_Rating-2 {
  background-color: $rating2;
}

.dr-Shared_Rating-1 {
  background-color: $rating1;
}

.dr-Shared_RatingCount {
  color: $gray;
  font-size: $font-size-sm;
}
