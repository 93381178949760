.cb-Primary {
  color: $blue;
  background: $white;
  border-radius: $border-radius-base;
  border: $width-border-thick solid $blue;
  padding: $padding-large-vertical;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $padding-large-vertical;
  margin-left: $padding-large-vertical;

  &:focus,
  &:active {
    background: $white;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  &:focus,
  &:active {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(
      to right,
      #eeeeee 10%,
      #dddddd 18%,
      #eeeeee 33%
    );
    background-size: 800px 104px;
    position: relative;
  }
}

.cb-Primary-active {
  color: $white;
  background: $blue;
  border-color: $blue;

  animation-duration: 2.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: $blue;
    background: linear-gradient(
      to right,
      $blue 10%,
      $blue-lighter 40%,
      $blue 20%
    );
    background-size: 800px 104px;
    position: relative;
}

.cb-Icon {
  font-size: $font-size-h5;
}

.cb-Icon-active {
  color: $white;
}
